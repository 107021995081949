import React, {FormEvent, useEffect, useState} from 'react';
import {KeycloakTokenSuccessResponse} from "../@types";

const KEYCLOAK_TOKEN_ENDPOINT = process.env.REACT_APP_KEYCLOAK_AUTH_ENDPOINT || '';

type LoginFormProps = {
    redirectTo: string
    hash?: string
}

const LoginForm = ({redirectTo, hash}: LoginFormProps) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [target, setTarget] = useState<string>('');

    useEffect(() => {
        if (!target) {
            return;
        }
        window.open(target, '_self')

    }, [target])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setErrorMessage("")

        if (!username || !password) {
            setErrorMessage('Hiányzó belépési adatok');
            return;
        }

        fetch(KEYCLOAK_TOKEN_ENDPOINT, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'client_id': 'buszrent-crm',
                'username': username,
                'password': password,
                'grant_type': 'password'
            })
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            throw response
        }).then((body: KeycloakTokenSuccessResponse) => {
            const base64Url = body.access_token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');

            const jwtPayload = JSON.parse(decodeURIComponent(escape(window.atob(base64))));

            const goTo = redirectTo ? redirectTo : (jwtPayload.entrypoint || 'https://br.buszrent.hu');
            const redirectToUrl = `${goTo}?t=${body.access_token}&r=${body.refresh_token}${hash || ''}`
            setTarget(redirectToUrl);
        }).catch(e => {
            if (e instanceof Response) {
                if (e.status >= 500) {
                    setErrorMessage('Nem sikerült kommunikálni az IAM szerverrel');
                    return
                }

                if (e.status >= 400) {
                    setErrorMessage('Sikertelen bejelentkezés');
                    return
                }
            }
            setErrorMessage("Nem sikerült kapcsolódni a szerverhez: " + e.message);
        })
    }

    return <form className={'login-form'} onSubmit={handleSubmit}>
        <header>
            <img className={'logo-image'} src={`/images/br_logo_vertical.svg`} alt='BuszRent logo'/>
            <h2>Bejelentkezés</h2>
        </header>
        <div>
            <label htmlFor='username'>Felhasználónév / E-mail cím:</label>
            <input id={username} type='text' value={username} onChange={(e) => setUsername(e.target.value)}/>
        </div>
        <div>
            <label htmlFor='password'>Jelszó:</label>
            <input id='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <div className={'button-holder'}>
            {!!target ?
                <a className="redirect-button" href={target}>Átirányítás a BuszRent rendszerébe</a> :
                <button type={'submit'}>Bejelentkezés</button>


            }
        </div>
        {errorMessage ? (<div className={'error-holder'}>
            <p>{errorMessage}</p>
        </div>) : null}
    </form>
}

export {
    LoginForm
}